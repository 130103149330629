<template>
    <div class="details">
        <div class="list">
            <div class="item" v-for="(item, index) in list" :key="index">
                <!-- 题型一 -->
                <div v-if="item.contextText.type == 1 && (rank == 0 || rank == 1)">
                    <div class="topic">{{ item.contextText.topic }}</div>
                    <div class="container">
                        <div class="words_item" v-for="(tem, ndex1) in item.contextText.content" :key="ndex1">
                            <div class="words_size">{{ tem }}</div>
                        </div>
                    </div>
                </div>
                <!-- 题型二 -->
                <div class="container2" v-if="item.contextText.type == 2 && (rank == 0 || rank == 2)">
                    <div class="topic">{{ item.contextText.topic }}</div>
                    <div class="headlineList">
                        <div class="headlineBox" v-for="(tem, inde1) in item.contextText.headline" :key="inde1">
                            <div class="headline_size1">{{ tem.pin }}</div>
                            <div class="headline_size">{{ tem.size }}</div>
                        </div>
                    </div>
                    <div class="contentBox_big">
                        <div class="contentBox" v-for="(_item, _index) in item.contextText.content" :key="_index">
                            <div :class="inde == 0 ? 'contentItem1' : 'contentItem'" v-for="(tem, inde) in _item"
                                :key="inde">
                                <div class="content_item">
                                    <div class="content_size1" v-show="tem.pin != 1">{{ tem.pin }}</div>
                                    <div class="content_size">{{ tem.size }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- 题型三 -->
                <div class="container3" v-if="item.contextText.type == 3 && (rank == 0 || rank == 2)">
                    <div class="topic">{{ item.contextText.topic }}</div>

                    <div class="container3_headline">{{ item.contextText.headline }}</div>
                    <div class="container3_content_size" v-for="(tem, ndex) in item.contextText.content" :key="ndex">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ tem }}</div>
                </div>
                <!-- 题型四 -->
                <div class="container4" v-if="item.contextText.type == 4 && (rank == 0 || rank == 3)">
                    <div class="topic">{{ item.contextText.topic }}</div>
                    <img v-show="promptContent.img" class="masterMap" :src="promptContent.img" alt="">
                    <div class="container4_headline">{{ material.title }}</div>
                    <div class="container3_content_size" v-for="(ite, ind1) in essay" :key="ind1">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ ite }}</div>
                    <div class="topic_title">{{ promptContent.title }}</div>
                    <div class="container3_content_size" v-for="(ite) in topic" :key="ite">
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ ite }}</div>
                </div>
            </div>
        </div>
        <div class="bottom" v-show="coversShow == false">
            <button class="btn" @click="toAnswer()" v-if="rank == 0">前往答题</button>
            <img class="play" v-show="rank != 0 && playShow" src="../assets/img/play.png" alt="" @click="play()">
            <img class="play2" v-show="rank != 0 && playShow == false" src="../assets/img/audio.gif" @click="stop()" alt="">
        </div>
        <div class="covers" v-if="coversShow">
            <img src="../assets/img/cat.gif" alt="">
        </div>
    </div>
</template>
<script>
import axios from 'axios'
import wx from 'weixin-js-sdk'
import sha1 from "sha1";
export default {
    name: "test",
    data() {
        return {
            list: [],//页面显示数据
            contextResultText: [],//测评所需文本
            promptContent: '',//第三题参考词语和图片
            material: '',//第三题文本内容
            essay: [],//第三题展示文本
            topic: [],//第三题提示
            markingStandard: '',//第三题打分依据
            rank: 0,//开始答题后控制第几题显示
            playShow: true,//开始答题和结束答题切换
            timer: null,//计时器
            tim: 0,//时间
            frist: '',//第一题答题结果返回
            second: '',//第一题答题结果返回
            third: '',//第三题答题结果返回
            speech: '',//第三题答题录音ID
            coversShow: false,
        }
    },
    created() {
        this.getData(this.$route.query.id)
        this.getTicket()

    },
    methods: {

        // 获取页面数据
        getData(i) {
            var that = this
            axios.get("/api/edu/educoursecatalog/selfAssessmentDetail?catalogId=" + i).then(res => {
                var data = res.data.data
                data.forEach(element => {
                    element.contextText = JSON.parse(element.contextText)
                    if (element.contextText.type == '2') {
                        element.contextText.headline = that.decomposition(element.contextText.headline, 'headline')
                        element.contextText.content = that.decomposition2(element.contextText.content)
                    } else if (element.contextText.type == '4') {
                        that.promptContent = element.contextText.content.prompt.promptContent
                        that.material = element.contextText.content.prompt.material
                        that.essay = that.material.essay
                        that.topic = that.promptContent.content
                        that.markingStandard = element.contextText.content.markingStandard
                    }
                    that.list.push(element)

                });

                // console.log(that.list);
                // console.log( that.list[that.rank ].contextResultText)
                that.totalPoints(14, ' 所需跳转的小程序appid，即小程序对应的以wx开头的id')
            }).catch(err => {
                console.log(err);
            })
        },
        // 数据解析
        decomposition(item, type) {
            for (let i = 0; i < item.length; i++) {
                if (item[i].indexOf("|") != -1) {
                    item[i] = item[i].split("|")
                } else {
                    item[i] = item[i].split("")
                }
            }
            if (type == 'headline') {
                var arr = []
                for (let j = 0; j < item[0].length; j++) {
                    var reg = /[^\u4E00-\u9FA5]/;
                    if (reg.test(item[0][j])) {
                        item[j][1].splice(j, 0, '1')
                    }
                    var obj = {
                        size: item[0][j],
                        pin: item[1][j],
                    }
                    arr.push(obj)
                }
                return arr
            } else if (type == 'content') {
                return item
            }
        },
        // 数据解析
        decomposition2(item) {
            for (let i = 0; i < item.length; i++) {
                item[i] = this.decomposition(item[i], 'content')
            }
            var array = []
            for (let i = 0; i < item.length; i++) {
                var arr = []
                for (let j = 0; j < item[i][0].length; j++) {
                    var reg = /[^\u4E00-\u9FA5]/;
                    if (reg.test(item[i][0][j])) {
                        item[i][1].splice(j, 0, '1')
                    }
                    var obj = {
                        size: item[i][0][j],
                        pin: item[i][1][j],
                    }
                    arr.push(obj)
                }
                array.push(arr)
            }
            return array
        },
        // 点击开始答题
        // 开始答题
        toAnswer() {
            this.rank = 1
        },
        // 开始
        play() {
            console.log('开始录音')
            wx.startRecord()
            this.playShow = false
            if (this.rank == 3) {
                this.timer = setInterval(() => {
                    this.tim++
                }, 1000)
            }
        },
        // 结束
        stop() {
            console.log('停止录音')
            var that = this
            wx.stopRecord({
                success: function (res) {
                    if (that.rank == 3) {
                        that.speech = res.localId
                        that.translateVoice(res.localId)
                    } else {
                        that.uploadVoice(res.localId)
                    }

                },
                fail: function (res) {
                    console.log(res);
                }
            });

            if (this.timer != null) {
                clearInterval(this.timer)
            }
        },
        // 下一题
        next() {
            if (this.rank < 3) {
                this.playShow = true
                this.rank++
            } else {
                var u = navigator.userAgent
                var url
                var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端 
                if (isiOS) {
                    url = this.$route.query.url
                } else {
                    url = window.location.href
                }
                this.$router.push({
                    path: "/result",
                    name: "Result",
                    params: {
                        frist: this.frist,//第一题结果返回
                        second: this.second,//第一题结果返回
                        third: this.third,//第三题结果返回
                        url: url,
                    }
                });
            }
        },
        // 微信初始化
        // 获得jsapi_ticket
        getTicket() {
            var that = this
            axios.get("/api/edu/caiHongKeyOrderInfo/myWx").then(res => {
                //  alert(res.data.data.ticket);
                that.startInit(res.data.data.ticket)
            }).catch(err => {
                console.log(err);
            })
        },
        // 通过config接口注入权限验证配置
        startInit(i) {
              var that = this
            var not = Math.round(new Date().getTime() / 1000).toString()
            var u = navigator.userAgent
            var url
            var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端 
            if (isiOS) {
                url = this.$route.query.url
            } else {
                url = window.location.href
            }
            var aa = "jsapi_ticket=" + i + "&noncestr=" + not + "&timestamp=" + not + "&url=" + url
            var signature = sha1(aa)
            wx.config({
                debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: 'wx68e7c88b82e0b02b', // 必填，公众号的唯一标识 wx68e7c88b82e0b02b
                timestamp: not, // 必填，生成签名的时间戳 
                nonceStr: not, // 必填，生成签名的随机串
                signature: signature,// 必填，签名
                jsApiList: ['startRecord', 'stopRecord', 'onVoiceRecordEnd', 'playVoice', 'stopVoice', 'uploadVoice', "translateVoice"],// 必填，需要使用的JS接口列表
                openTagList: ['wx-open-launch-weapp'] // 需要使用的开放标签列表

            });
            wx.ready(function () {
                wx.onVoiceRecordEnd({
                    // 录音时间超过一分钟没有停止的时候会执行 complete 回调
                    complete: function (res) {
                        that.stop()
                    }
                });
            });


        },
        // 语音识别
        translateVoice(i) {
            var that = this
            wx.translateVoice({
                localId: i, // 需要识别的音频的本地Id，由录音相关接口获得
                isShowProgressTips: 1, // 默认为1，显示进度提示
                success: function (res) {
                    // alert(res.translateResult); // 语音识别的结果
                    that.totalPoints(that.tim, res.translateResult)
                }
            });
        },
        // 上传录音
        uploadVoice(i) {
            var that = this
            wx.uploadVoice({
                localId: i, // 需要上传的音频的本地ID，由stopRecord接口获得
                isShowProgressTips: 1, // 默认为1，显示进度提示
                success: function (res) {
                    // alert(res.serverId)
                    that.evaluation(res.serverId, that.list[that.rank - 1].contextResultText)
                }
            });
        },
        // 测评接口
        evaluation(id, txt) {
            var that = this
            this.coversShow = true
            axios.get("/api/edu/miniAppEvaluation/pcEvaluation?mediaId=" + id + "&pcEvaluationText=" + txt,
                // {
                //     mediaId: id,
                //     pcEvaluationText: this.contextResultText[this.rank - 1]
                // }
            ).then(res => {
                //  alert(JSON.stringify(res.data.data));
                if (that.rank == 1) {
                    // that.frist.content = res.data
                    that.frist = {
                        title: that.list[0].contextText.topic,
                        content: res.data.data,
                    }
                } else if (that.rank == 2) {
                    // that.second.content = res.data
                    that.second = {
                        title: that.list[1].contextText.topic,
                        content: res.data.data,
                    }
                }
                that.coversShow = false
                that.next()
            }).catch(err => {
                console.log(err);
            })
        },
        // 命题说话分数计算
        totalPoints(time, words) {

            var wordsnum = words.length
            var total = 0
            var threePercent = parseInt(this.markingStandard.time * 0.3)
            var threePercent2 = parseInt(this.markingStandard.words * 0.3)
            var timescore = this.timeSize(this.markingStandard.time, time, 'time')
            var wordsscore = this.timeSize(this.markingStandard.words, wordsnum, 'words')
            console.log('threePercent=' + threePercent); console.log('threePercent2=' + threePercent2);
            console.log('timescore=' + timescore); console.log('wordsscore=' + wordsscore);
            var fluencyscore = Number(timescore) + Number(wordsscore)
            if (time < threePercent || words < threePercent2) {

                total = fluencyscore
            } else {

                var topicscore = this.topicContent(this.markingStandard.topicKeywords, 'topicKeywords', fluencyscore, words)
                var contentscore = this.topicContent(this.markingStandard.contentKeywords, 'contentKeywords', fluencyscore, words)
                var sequentialscore = this.sequential(fluencyscore, words)
                var exemptscore = this.exempt(words)
                total = Number(fluencyscore) + Number(topicscore) + Number(contentscore) +
                    Number(sequentialscore) + Number(exemptscore)
            }
            //   console.log('total='+total)
            this.speecScore = total
            this.third = {
                title: this.list[2].contextText.topic,
                score: total,
                localId: this.speech
            }
            this.next()
            // console.log(total);
        },
        // 时间、字数得分
        timeSize(criterion, actual, type) {
            console.log(criterion, actual, type);
            // 满分的最大容忍值，满分的最小容忍值，满分，多少扣一分,得分,百分三十
            var fullMax, fullMin, score, buckle, goal, threePercent
            fullMax = Number(criterion) + parseInt(criterion * 0.8)
            fullMin = parseInt(criterion * 0.8)
            threePercent = parseInt(criterion * 0.3)
            if (type == "time") {
                score = 25
                buckle = parseInt(Number(criterion) * 0.05)
            } else if (type == "words") {
                score = 10
                buckle = parseInt(Number(criterion) * 0.1)
            }
            var temporary
            if (actual < threePercent) {
                temporary = Number(score) * 0.1
            } else {
                if (actual > fullMin && actual < fullMax) {
                    temporary = score
                } else if (actual < fullMin) {
                    temporary = Number(score) - parseInt((Number(fullMin) - Number(actual)) / Number(buckle))
                } else if (actual > fullMax) {
                    temporary = Number(score) - parseInt((Number(actual) - Number(fullMax)) / Number(buckle))
                }
            }
            goal = temporary < Number(score) * 0.1 ? Number(score) * 0.1 : temporary
            return goal
        },
        // 主题、内容关键字得分
        topicContent(criterion, type, fluency, cont) {
            var score, goal, basics, cardinal
            if (type == "topicKeywords") {
                score = 10
                basics = 5
                cardinal = 5
            } else if (type == "contentKeywords") {
                score = 25
                basics = 10
                cardinal = 15
            }
            if (criterion) {
                var temporary
                var arr = []
                for (let i = 0; i < criterion.length; i++) {
                    if (cont.indexOf(criterion[i]) != -1) {
                        arr.push(cont.indexOf(criterion[i]))
                    }
                }
                temporary = score - ((criterion.length - arr.length) * Math.floor(score / criterion.length))
                goal = temporary < Number(score) * 0.1 ? Number(score) * 0.1 : temporary
            } else {
                goal = Number(basics) + Number(fluency) / 35 * Number(cardinal)
            }
            return goal
        },
        // 顺序关键字得分
        sequential(fluency, cont) {
            var markingStandard = this.markingStandard
            var goal = 5
            if (markingStandard.sequentialKeywords) {
                var arr = []
                for (let i = 0; i < markingStandard.sequentialKeywords.length; i++) {
                    if (cont.indexOf(markingStandard.sequentialKeywords[i]) == -1) {
                        goal = 0
                        break
                    } else {
                        arr.push(cont.indexOf(markingStandard.sequentialKeywords[i]))
                    }
                }
                // console.log(JSON.stringify(arr));
                if (goal != 0) {
                    for (let i = 0; i < arr.length; i++) {
                        if (i < arr.length) {
                            if (arr[i] > arr[i + 1]) {
                                goal = 0
                                break
                            } else {
                                goal = 5
                            }
                        }
                    }
                }
            } else {
                goal = 2 + Number(fluency) / 35 * 3
            }
            return goal
        },
        // 关键字重复得分
        exempt(cont) {
            var markingStandard = this.markingStandard
            var goal = 5
            if (markingStandard.exemptKeywords) {
                var repeat = [...markingStandard.topicKeywords, ...markingStandard.contentKeywords, ...markingStandard
                    .sequentialKeywords
                ]
                for (let i = 0; i < markingStandard.exemptKeywords.length; i++) {
                    if (repeat.indexOf(markingStandard.exemptKeywords[i]) != -1) {
                        repeat.splice(repeat.indexOf(markingStandard.exemptKeywords[i]), 1)
                        i--
                    }
                }
                console.log(repeat);
                var arr = []
                for (let i = 0; i < repeat.length; i++) {
                    var index = cont.indexOf(repeat[i])
                    var num = 0
                    while (index != -1) {
                        num++
                        index = cont.indexOf(repeat[i], index + 1)
                    }
                    arr.push(num)
                }

                for (let i = 0; i < arr.length; i++) {
                    if (arr[i] > 5) {
                        goal = 0
                    }
                }
            } else {
                goal = 5
            }
            return goal
        },
    }
}
</script>
<style scoped>
.details {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    text-align: left;
    background: #fafafa;
}

.list {
    width: 100%;
    height: 88%;
    overflow-y: scroll;

}

.item {
    width: 100%;
    background: #fff;
    border-radius: 9px;
    padding: 0 15px;
    margin-bottom: 15px;
}

.topic {
    font-size: 15px;
    font-weight: bold;
    color: #210600;
    margin-bottom: 10px;
    line-height: 30px;
}

/*  题型一 */
.container {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
}

.words_item {
    width: 30%;
    padding: 10px 13.5px;
    background: #f7f5f5;
    margin: 5px;
    border-radius: 4px;
}

.words_size {
    font-size: 14px;
    text-align: center;
    font-weight: 400;
    color: #210600;
}

/*  题型二 */
.headlineList {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-bottom: 12px;
}

.headlineBox {
    margin: 0 3px;
    align-items: center;
}

.headline_size1 {
    font-size: 14px;
    font-weight: 400;
    color: #210600;
    text-align: center;
    margin-bottom: 5px;
}

.headline_size {
    font-size: 16px;
    font-weight: 400;
    color: #210600;
    text-align: center;
}

.contentBox_big {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
}

.contentBox {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
    margin-bottom: 10px;
}

.contentItem {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.contentItem1 {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 24px;
}

.content_item {
    margin-right: 3px;
    align-items: center;
}

.content_size1 {
    font-size: 12px;
    font-weight: 400;
    color: #210600;
    line-height: 20px;
    text-align: center;
}

.content_size {
    text-align: center;
    font-size: 14px;
    font-weight: 400;
    color: #210600;
    line-height: 20px;
}

/* 题型三 */
.container3_headline {
    font-size: 16px;
    font-weight: 400;
    color: #210600;
    text-align: center;
    margin-bottom: 12px;
}

.container3_content_size {
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
    color: #210600;
    margin-bottom: 4px;
}

/* 题型四 */
.masterMap {
    width: 84%;
    height: 200px;
    border-radius: 9px;
    margin: 15px 0 12px 8%;
}

.container4_headline {
    font-size: 16px;
    font-weight: 400;
    color: #210600;
    margin-bottom: 12px;
    text-align: center;
}

.topic_title {
    font-size: 16px;
    font-weight: bold;
    color: #210600;
    margin-top: 12px;
    margin-bottom: 12px;
}

/* 底部操作区域 */
.bottom {
    width: 100%;
    height: 10%;

}

.btn {
    width: 84%;
    height: 80%;
    background-image: linear-gradient(to right, #ffb940, #fb834e);
    border-radius: 20px;
    font-size: 18px;
    font-weight: 400;
    line-height: 35px;
    color: #fff9f5;
    border: none;
    margin: 4% 0 0 8%;
}

.play {
    width: 50px;
    height: 50px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    margin-left: -25px;
}

.play2 {
    width: 70px;
    height: 70px;
    position: absolute;
    bottom: 3px;
    left: 50%;
    margin-left: -35px;
}

.covers {
    width: 150px;
    height: 100px;
    border-radius: 8px;
    position: fixed;
    top: 50%;
    left: 50%;
    margin: -50px 0 0 -75px;

}
</style>